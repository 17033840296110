import React, { FunctionComponent } from "react"
import Layout from "./Layout"
import { StoreLocation } from "../models/storeLocation"
import MapComponent from "./MapComponent"

interface OwnProps {
  mapLocation: string
  mapZoomLevel: number
  selectedLocation?: StoreLocation
}

type Props = OwnProps

const LayoutWithMap: FunctionComponent<Props> = props => {
  return (
    <Layout selectedLocation={props.selectedLocation}>
      <div className={"flex flex-col lg:flex-row"}>
        <div className={"hidden lg:block flex flex-col"}>
          {/*empty divs for spacing*/}
          <div className={"h-64"} />
          <div className={"h-40"} />
        </div>
        <div className={"flex flex-row lg:w-1/2 p-2 md:p-4"}>
          <div className={"lg:hidden flex flex-col"}>
            {/*empty divs for spacing*/}
            <div className={"h-64"} />
            <div className={"h-16 md:hidden"} />
            <div className={"hidden md:block h-40"} />
          </div>
          <MapComponent
            location={props.mapLocation}
            zoomLevel={props.mapZoomLevel}
          />
        </div>
        {props.children}
      </div>
    </Layout>
  )
}

export default LayoutWithMap
