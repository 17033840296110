import React, { FunctionComponent } from "react"
import logo from "../../content/images/logo.svg"

interface OwnProps {}

type Props = OwnProps

const Header: FunctionComponent = () => {
  return (
    <header
      className={"hidden lg:flex flex-row justify-center my-4 lg:my-6 w-full"}
    >
      <img
        src={logo}
        alt=""
        className={"self-center h-16 md:h-24 lg:h-32 w-auto"}
      />
    </header>
  )
}

export default Header
