import React, { FunctionComponent } from "react"
import Header from "./Header"
import NavBar from "./NavBar"
import Footer from "./Footer"
import { StoreLocation } from "../models/storeLocation"
import { Helmet } from "react-helmet"

interface OwnProps {
  selectedLocation?: StoreLocation
}

type Props = OwnProps

const Layout: FunctionComponent<Props> = props => {
  return (
    <>
      <Helmet defaultTitle={"Tote-A-Poke"}>
        <meta
          name={"description"}
          content={
            "Tote-A-Poke convenience stores offer a wide variety of items and services throughout eastern Oklahoma."
          }
        />
        <meta property="og:title" content="Tote-A-Poke" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://tote-a-poke.keeton.dev/" />
        <link rel="canonical" href="https://tote-a-poke.keeton.dev/" />
        <html lang={"en"} />
      </Helmet>
      <div className={"flex flex-col w-screen min-h-screen font-lato"}>
        <Header />
        <NavBar selected={props.selectedLocation} />
        <main
          className={
            "pt-16 lg:pt-0 flex flex-col justify-center flex-grow container mx-auto"
          }
        >
          {props.children}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
