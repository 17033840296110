import React, { PureComponent } from "react"

interface OwnProps {}

type Props = OwnProps

class Footer extends PureComponent<Props> {
  render() {
    return (
      <footer className={"bg-black h-auto justify-center"}>
        <div className={"text-white text-center py-3 lg:py-6"}>
          <div className={"text-white text-xs lg:text-sm"}>
            <p className={"text-s lg:text-base font-bold"}>Corporate Contact</p>
            <p>2019 N. Broadway, Poteau, OK 74953</p>
            <p>
              <a className={"hover:text-yellow-400"} href="tel:+19186479946">
                (918) 647-9946
              </a>{" "}
              or{" "}
              <a className={"hover:text-yellow-400"} href="tel:+19186479938">
                (918) 647-9938
              </a>
            </p>
            <p>
              <a
                className={"hover:text-yellow-400"}
                href="mailto:tote-a-poke@clnk.com"
              >
                tote-a-poke@clnk.com
              </a>
            </p>
          </div>
          <div className={"pt-3 text-xs"}>
            <p>
              &copy; 2020{" "}
              <a
                className={"text-yellow-400 hover:text-yellow-300"}
                href="https://keeton.dev"
              >
                keeton.dev
              </a>
            </p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
