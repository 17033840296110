import React, { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet"
import { BarLoader } from "react-spinners"

interface OwnProps {
  location?: string
  zoomLevel?: number
}

type Props = OwnProps

const apiKey = "AIzaSyC1PKM6o2MkDe__bCWBXRegAr3hp6EfWTE"
const defaultProps: Props = {
  location: "Oklahoma",
  zoomLevel: 14,
}

const MapComponent: FunctionComponent<Props> = ({
  location = defaultProps.location,
  zoomLevel = defaultProps.zoomLevel,
}: Props) => {
  const [mapLoading, setMapLoading] = useState(true)

  const googleMapUrl = `https://www.google.com/maps/embed/v1/search?key=${apiKey}&q=Tote-A-Poke+${location}&zoom=${zoomLevel}`

  return (
    <>
      <Helmet>
        <link rel={"preconnect"} href={"https://google.com"} />
        <link rel={"preconnect"} href={"https://maps.googleapis.com"} />
      </Helmet>
      <div className={"w-full bg-gray-100"}>
        <BarLoader
          loading={mapLoading}
          color={"#ffcc01"}
          height={7}
          width={200}
          css={
            "margin: 0; top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);"
          }
        />
        <iframe
          className={"w-full h-full"}
          title={"map"}
          frameBorder="0"
          src={googleMapUrl}
          allowFullScreen
          referrerPolicy={"strict-origin"}
          onLoad={() => setMapLoading(false)}
        />
      </div>
    </>
  )
}

export default MapComponent
