import React, { FunctionComponent, useState } from "react"
import { Link } from "gatsby"
import { TiHome } from "react-icons/ti"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"
import { StoreLocation } from "../models/storeLocation"
import logo from "../../content/images/logo.svg"

interface OwnProps {
  selected?: StoreLocation
}

type Props = OwnProps

const NavBar: FunctionComponent<Props> = ({ selected }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <nav
      className={
        "fixed lg:static flex flex-col lg:flex-row w-full lg:justify-center justify-between items-center bg-black p-2 shadow lg:shadow-none z-50"
      }
    >
      <div
        className={
          "w-full lg:w-auto self-start lg:self-center flex flex-row lg:flex-none flex-no-wrap justify-between items-center"
        }
      >
        <Link to="/">
          <img
            src={logo}
            alt=""
            className={"lg:hidden self-center h-10 w-auto m-1"}
          />
        </Link>
        <div className={"flex flex-row"}>
          <Link to="/">
            <TiHome
              size={"24"}
              className={
                "lg:hidden mx-2 mt-1 text-white active:text-yellow-300"
              }
            />
          </Link>
          {menuOpen ? (
            <button
              name={"Close Dropdown Menu"}
              id={"menuBtn"}
              className={`block text-white px-3 py-2 lg:hidden focus:outline-none`}
              type={"button"}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <AiOutlineClose />
            </button>
          ) : (
            <button
              name={"Open Dropdown Menu"}
              id={"menuBtn"}
              className={`block text-white px-3 py-2 lg:hidden focus:outline-none`}
              type={"button"}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <AiOutlineMenu />
            </button>
          )}
        </div>
      </div>
      <div
        id={"menu"}
        className={
          "text-white py-2 lg:py-0 w-full lg:w-auto self-end lg:self-center lg:flex flex-col lg:flex-row items-center h-full " +
          (menuOpen ? "flex" : "hidden")
        }
      >
        <Link to="/">
          <TiHome
            style={{ height: 24, width: 24, textAlign: "center" }}
            size={"24"}
            className={
              "hidden lg:inline mx-2 hover:text-yellow-300 transition duration-150 ease-in-out" +
              (selected ? "" : " text-yellow-400")
            }
          />
        </Link>
        <Link
          to="/"
          className={
            "lg:hidden mx-2 text-xl capitalize font-bold hover:text-yellow-300" +
            (selected ? "" : " text-yellow-400")
          }
        >
          Home
        </Link>
        {Object.keys(StoreLocation).map(location => (
          <Link
            key={location}
            to={`/locations/${location.toLowerCase()}`}
            className={
              "mx-2 text-xl capitalize font-bold hover:text-yellow-300 transition duration-150 ease-in-out" +
              (location === selected ? " text-yellow-400" : "")
            }
          >
            {location}
          </Link>
        ))}
      </div>
    </nav>
  )
}

export default NavBar
